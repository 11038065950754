
<script>
import appConfig from "@/app.config";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Stat from "@/components/widgets/stat";
import { required, email } from "vuelidate/lib/validators";

import {
  userMethods,
  eventMethods,
  liveshoppingAnalyticsMethods,
} from "@/state/helpers";

export default {
  page: {
    title: "Profile",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Stat
  },
  data() {
    return {
      items:[],
      title: "Profile",
      statData: [
        {
          icon: "bx bx-video",
          title: "Total Eventos",
          value: "137"
        },
        {
          icon: "bx bx-check-circle",
          title: "Completados",
          value: "125"
        },
        {
          icon: "bx bx-hourglass",
          title: "Pendientes",
          value: "12"
        },
      ],
      user:{},
      submitted:false,
      profilePictureUrl : null,
      totalEvents : 0,
      completedEvents :0,
      pendingEvents : 0,
      events:[]
    }
  },
  validations: {
		user: {
			name: {
				required,
			},
			email: {
					required,
					email,
			},
		}
	},
  computed: {
   
  },
  methods: {
    ...userMethods,
    ...eventMethods,
    ...liveshoppingAnalyticsMethods,

    formatDate(d) {
      
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
      return [year, month, day].join('-');
    },

    getStats() {

      let today = new Date();

      const firstday = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let query = `dateFrom=${this.formatDate(firstday)}&dateTo=${this.formatDate(lastday)}&host=${this.user._id}`
      
      this.getEventsList(query).then((res)=>{
        if(res.total && res.total.length > 0){
         this.totalEvents = res.total[0].total;
        }else{
          this.totalEvents = '-'
        }
      });
    }, 

    getLastEvents(){

      let today = new Date();

      const firstday = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let query = `dateFrom=${this.formatDate(firstday)}&dateTo=${this.formatDate(lastday)}&host=${this.user._id}&status=closed`
      
      this.getEventsList(query).then((res)=>{
        this.events = res.events || [];
        if(res.total && res.total.length > 0){
         this.completedEvents = res.total[0].total;
        }else{
          this.completedEvents = '-'
        }

      }).catch().finally(()=>{
        
      });
    },  
    updateProfilePicture() {
      var file = this.$refs.profilePicture.files[0];
      this.$refs.profilePicture.files = null;

      if(file){
        this.uploadPicture(file).then((res) => {
          this.profilePictureUrl = res.data.url;
          
          this.user.imageURL = res.data.url;
          
          this.updateAuthUser();
          
          this.updateProfile();
          return true;                  
        })
        .catch(() => {
          this.$notify({ type: 'error', text: this.$t("profile.updateProfilePicture_error"),title:  this.$t("profile.updateProfilePicture") });
        })
      }
    },		
    
    openFileUpload() {
     this.$refs.profilePicture.click();
    },

    updateProfile() {

      this.submitted = true;
      this.$v.$touch();

			if (this.$v.$invalid) {
				return;
      }
      
      this.updateUserProfile(this.user).then(()=>{
        
        this.$notify({ type: 'success', text: this.$t("profile.updateProfile_success"),title:  this.$t("profile.updateProfile") });

        this.updateAuthUser();
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("profile.updateProfile_error"),title:  this.$t("profile.updateProfile") });
      })
    },

    updateAuthUser() {
      var authUser = JSON.parse(localStorage.getItem("auth.currentUser"));
      authUser.user = this.user;

      localStorage.setItem("auth.currentUser",JSON.stringify(authUser));
    }
    
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("auth.currentUser")).user;
    this.profilePictureUrl = this.user.imageURL;
    
    this.getStats();
    this.getLastEvents();
  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('profile.title')" :items="items" />
    <div class="row">
      <div class="col-xl-4">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-3">
                  <h5 class="text-primary">{{$t("login.welcome") + " " + this.user.name + "!"}}</h5>
                  <p v-if="user?.role=='host'">{{$t("profile.subtitle")}}</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-sm-12">
                <div class="avatar-md profile-user-wid mb-4 position-relative" v-on:click="openFileUpload" >
                  <img
                    :src="profilePictureUrl"
                    alt
                    class="img-thumbnail rounded-circle profile-picture"
                    v-if="profilePictureUrl"
                  />
                  <div class="avatar-md header-profile-user header-profile-user-color" v-if="!profilePictureUrl">
                    <span class="avatar-title rounded-circle header-profile-user">{{ user.name ? user.name.charAt(0) : ""}}</span>
                  </div>
                  <div class="avatar-xs me-3 edit-picture-button">
                    <span class="avatar-title bg-success rounded-circle font-size-15">
                      <i class="bx bx-pencil"></i>
                    </span>
                    <input
                          ref="profilePicture"
                          @change="updateProfilePicture"
                          hidden
                          type="file"
                          accept="image/*"
                          class="form-control"
                          id="profilePictture"
                        />
                  </div>
                </div>
                <h5 class="font-size-15 text-truncate">{{user.name}}</h5>
                <p class="text-muted mb-0 text-truncate">{{user.role}}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->

        <div :class="user.role=='host' ? 'card' : ' card col-xl'">
          <div class="card-body">
            <h4 class="card-title mb-4">{{$t("profile.information")}}</h4>

            <p></p>
            
            <div class="mb-3">
                <label for="name">{{$t('users.name')}}</label>
                <input
                id="name"
                v-model="user.name"
                type="text"
                class="form-control"
                :placeholder="$t('users.name_placeholder')"
                :class="{
                    'is-invalid':
                    submitted && $v.user.name.$error,
                }"
                />
                <div
                v-if="
                    submitted && !$v.user.name.required
                "
                class="invalid-feedback"
                >
                {{$t('users.name_required')}}
                </div>
            </div>
            <div class="mb-3">
                <label for="email">{{$t('users.email')}}</label>
                <input
                id="email"
                v-model="user.email"
                type="email"
                class="form-control"
                :placeholder="$t('users.email_placeholder')"
                :class="{
                    'is-invalid':
                    submitted && $v.user.email.$error,
                }"
                />
                <div
                v-if="submitted && !$v.user.email.required"
                class="invalid-feedback"
                >
                {{$t('users.email_required')}}
                </div>
            </div>
            <div class="text-end mt-3">
                <b-button @click="updateProfile" variant="primary" class="ms-1"
                >{{$t('common.update')}}</b-button>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>

      <div class="col-xl-8" v-if="user.role=='host'">
        <div class="row">
          <div class="col-md-6">
            <Stat icon="bx bx-video" :title="$t('profile.total_events')" :value="totalEvents.toString()" />
          </div>
          <div class="col-md-6">
            <Stat icon="bx bx-check-circle" :title="$t('profile.completed_events')" :value="completedEvents.toString()" />
          </div>
        </div>
        <div class="card" v-if = "false">
          <div class="card-body">
            <h4 class="card-title mb-4">Eventos</h4>
            <div id="revenue-chart" class="apex-charts"></div>
            <apexchart
              class="apex-charts"
              type="bar"
              height="320"
              :series="revenueChart.series"
              :options="revenueChart.chartOptions"
            ></apexchart>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ $t('profile.last_events')}}</h4>
            <div class="table-responsive">
              <table class="table table-nowrap table-hover mb-0">
                <thead>
                  <tr>
                    <th scope="col">{{$t('events_list.name')}}</th>
                    <th scope="col">{{$t('events_list.date')}}</th>
                    <th scope="col">{{$t('events_list.guest')}}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="event in events" :key="event._id">
                    <td>{{event.event?.title}}</td>
                    <td>{{ `${new Date(event.event?.date).toLocaleDateString()} ${new Date(event.event?.date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`}}</td>
                    <td>{{ event.event?.invites[0].email }}</td>
                    <td class="bold" v-if="event.event?.status != 'scheduled'">
													{{`$ ${parseFloat(event.total_cart).toLocaleString('es') }`}}
												</td>
												<td>
												</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.header-image-text {
  display: inline-flex;
  align-items: center;
} 

.header-profile-user-color {
  background: transparent;
}

.edit-picture-button {
    height: 1.5rem !important;
    width: 1.5rem !important;
    right: 0px;
    margin-top: -15px;
    margin-right: 5px;
    position: absolute;
}

.profile-picture {
    height: 70px !important;
    width: 70px !important;
}

.interact{
	cursor: pointer;
  }

.bold{
	font-weight: bold;
}
</style>